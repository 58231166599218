<template>
  <div class="container section is-main-section" >
    <div class="columns is-centered">
      <div class="column is-4" id="loginForm">

        <button @click="auth('google')" class="button button-google is-fullwidth">
          <span class="icon">
            <i class="fab fa-google-plus-g"></i>
          </span>
          <span>Sign in with Google</span>
        </button>

        <div class="is-divider" data-content="OR"></div>

        <div class="field">
          <p class="control has-icons-left has-icons-right">
            <input class="input" type="text"
                   placeholder="Login" v-model="username">
            <span class="icon is-small is-left">
              <i class="fa fa-envelope"></i>
            </span>
            <span class="icon is-small is-right">
              <i class="fa fa-check"></i>
            </span>
          </p>
        </div>
        <div class="field">
          <p class="control has-icons-left">
            <input class="input" type="password"
                   placeholder="Password" v-model="password">
            <span class="icon is-small is-left">
              <i class="fa fa-lock"></i>
            </span>
          </p>
        </div>
        <div class="field">
          <p class="control">
            <button class="button is-success is-fullwidth" @click="login">
              Login
            </button>
          </p>
        </div>

        <div class="is-divider" data-content="OR"></div>

        <form v-on:submit.prevent="tokenForm">
          <div class="field">
            <label class="label">API token</label>
            <div class="control">
              <textarea class="textarea has-fixed-size" v-model="apiToken">
              </textarea>
            </div>
          </div>
          <div class="control">
            <button class="button is-primary is-fullwidth">Login</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'login',
  data() {
    return {
      username: '',
      password: '',
      apiToken: '',
    };
  },
  beforeCreate() {
    if (localStorage.getItem('apiToken')) {
      this.redirect();
    }
  },
  created() {
    const { token } = this.$route.query;
    if (token !== undefined) {
      this.apiToken = token;
      this.tokenForm();
    }
  },
  methods: {
    tokenForm() {
      if (!this.apiToken) {
        this.$toast.error('Please fill API token');
      } else {
        this.$auth.setToken({ access_token: this.apiToken });
        this.$store.commit('token', this.$auth.getPayload());
        this.redirect();
      }
    },
    login() {
      this.$auth.login({ login: this.username, password: this.password }).then(() => {
        this.$store.commit('token', this.$auth.getPayload());
        this.redirect();
      });
    },
    auth(provider) {
      this.$auth.authenticate(provider).then((authResponse) => {
        this.$auth.setToken(authResponse);
        this.$store.commit('token', this.$auth.getPayload());
        this.redirect();
      });
    },
    redirect() {
      const path = localStorage.getItem('redirectPath');
      if (path) {
        localStorage.removeItem('redirectPath');
        this.$router.push({ path });
      } else {
        this.$router.push({ name: 'organizations' });
      }
    },
  },
};
</script>

<style>
.button-google { background-color: #ea4335; color: white; }
</style>
